import { FSA } from "flux-standard-action"
import _ from "lodash"
import { ReducersMapObject } from "redux"
import { handleActions } from "redux-actions"
import actions from "./actions"
import {
  Mission,
  MissionInteractionStatus,
  MissionLoadingStatus,
  MissionState
} from "./models"

export const initialState: MissionState = {
  allMissionsLoadingStatus: MissionLoadingStatus.INITIAL,
  missionInteractionStatus: MissionInteractionStatus.INITIAL,
  allMissions: null
}

export const onPersistState = (state: MissionState): MissionState => {
  return {
    ...state,
    allMissions: (state.allMissions || []).map(Mission.serialize),
    allMissionsLoadingStatus: MissionLoadingStatus.INITIAL,
    missionInteractionStatus: MissionInteractionStatus.INITIAL
  }
}

export const onReviveState = (state: MissionState): MissionState => {
  return {
    ...state,
    allMissions: (state.allMissions || []).map(Mission.deserialize)
  }
}

const reducerMap = {
  [actions.setAllMissionLoadingStatus.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): MissionState => {
    return {
      ...state,
      allMissionsLoadingStatus: payload
    }
  },
  [actions.setMissionInteractionStatus.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): MissionState => {
    return {
      ...state,
      missionInteractionStatus: payload
    }
  },
  [actions.getAllMissions.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): MissionState => {
    return {
      ...state,
      allMissions:
        payload &&
        payload.data &&
        payload.data.map((mission: any) => Mission.fromAPI(mission, state))
    }
  },
  [actions.storeTaskData.toString()]: (
    state: MissionState,
    { payload }: FSA<string, any>
  ): MissionState => {
    if (!state.allMissions) {
      throw new Error("Cannot store task data, missions not loaded yet...")
    }

    return {
      ...state,
      allMissions: state.allMissions.map((mission) =>
        mission.missionUuid === payload.missionUuid
          ? mission.updateTaskResponses(payload.responses)
          : mission
      )
    }
  },
  [actions.clearTaskData.toString()]: (
    state: MissionState,
    { payload }: FSA<string, any>
  ): MissionState => {
    if (!state.allMissions) {
      throw new Error("Cannot clear task data, missions not loaded yet...")
    }

    return {
      ...state,
      allMissions: state.allMissions.map((mission) =>
        mission.missionUuid === payload.missionUuid
          ? mission.clearTaskResponses()
          : mission
      )
    }
  },
  [actions.getMissionAndTasks.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): MissionState => {
    const newMission =
      payload &&
      payload.data &&
      Mission.fromAPI(
        {
          ...payload.data.mission,
          tasks: payload.data.tasks
        },
        state
      )

    return {
      ...state,
      allMissions:
        newMission &&
        state.allMissions &&
        state.allMissions.map((mission: Mission) =>
          mission.missionUuid === newMission.missionUuid ? newMission : mission
        )
    }
  }
} as ReducersMapObject

export default handleActions<MissionState>(reducerMap, initialState)
