import { createActions } from "redux-actions"

const prefix = "USER"

const actionMap = {}

const actionTypes = [
  "LOGIN",
  "LOGOUT",
  "PRIVATE_LOGIN",
  "RESET_PASSWORD",
  "VALIDATE_EMAIL",
  "VALIDATE_SMS_CODE",
  "UPDATE_PASSWORD",
  "GET_USER_PROFILE",
  "UPDATE_PROFILE",
  "REGISTER_USER"
]

export default createActions(actionMap, ...actionTypes, { prefix })
