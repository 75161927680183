import _ from "lodash"

// tslint:disable-next-line:only-arrow-functions
export const assignWithNewProps = function<T>(
  newObject: T,
  original: Partial<T> | undefined,
  newProps: Partial<T>
) {
  Object.assign(
    newObject,
    _.fromPairs(
      Object.entries(original || {}).filter(
        ([, value]) => typeof value !== "function"
      )
    ),
    newProps
  )
}
