import Typography from "@/components/foundation/typography"
import { HTMLInputProps } from "@/types/props"
import classNames from "classnames"
import React, { useRef } from "react"
import style from "./style.scss"

export interface InputProps extends Omit<HTMLInputProps, "label"> {
  /**
   * Text label for the control.
   *
   * Use `children` to supply JSX content.
   */
  label?: string | React.ReactNode
  /**
   * Visible shows the label. Hidden hides the label, but displays it for screenreaders
   */
  labelStyle?: "visible" | "hidden"
  /**
   * Position of the label
   */
  labelPosition?: "Left" | "Center" | "Right"
  /**
   * Name of the HTML tag that wraps the checkbox.
   *
   * By default a `<label>` is used, which effectively enlarges the click
   * target to include all of its children. Supply a different tag name if
   * this behavior is undesirable or you're listening to click events from a
   * parent element (as the label can register duplicate clicks).
   *
   * @default "label"
   */
  tagName?: keyof JSX.IntrinsicElements
  /**
   * Name of the HTML tag for the input.
   *
   * @default "input"
   */
  inputTagName?: "input" | "select" | "textarea"
  /**
   * Error message for the control.
   */
  error?: string
  /**
   * Error message for the control.
   */
  errorPosition?: "top" | "bottom"
  /**
   * Visible ensures the space for the error is allocated before the error appears. Hidden pushes the layout around to make space for the error.
   */
  errorStyle?: "visible" | "hidden"
  /**
   * If a user has interacted with the form input, `touched` will be true.
   */
  touched?: boolean
  /**
   * Ref for the inner <input /> node
   */
  inputRef?: React.RefObject<HTMLInputElement | undefined>
  /**
   * Custom styling override
   */
  overrideStyles?: { [className: string]: string }
  /**
   * Styling variation
   */
  variation?: "default" | "square" | "light"
}

const InputError: React.SFC<Readonly<InputProps>> = (props) => {
  const { touched, error, overrideStyles } = props
  return (
    <div
      className={classNames(
        style.error,
        overrideStyles && overrideStyles.inputError
      )}
    >
      <Typography.P3>{touched && error}</Typography.P3>
    </div>
  )
}

const Input: React.SFC<Readonly<InputProps>> = (props) => {
  const {
    label,
    labelStyle = "hidden",
    labelPosition = "Left",
    error,
    touched,
    className,
    variation = "default",
    errorPosition = "top",
    errorStyle = "visible",
    value: value = "",
    tagName: TagName = "label",
    inputTagName: InputTagName = "input",
    overrideStyles,
    inputRef = useRef<HTMLInputElement>(),
    ...inputProps
  } = props

  const classes = classNames(
    style.wrapper,
    style[`wrapper--${variation}`],
    style[`wrapper--label${labelPosition}`],
    {
      [style["wrapper--" + (props.tagName || inputProps.type || "text")]]: true,
      [style["wrapper--touched"]]: touched,
      [style["wrapper--error"]]:
        !inputProps.disabled && !inputProps.readOnly && error && touched,
      [style["wrapper--disabled"]]: inputProps.disabled || inputProps.readOnly
    },
    className
  )

  const showError = errorStyle !== "hidden" || props.error

  const getSelectLabel = (): string => {
    if (!inputRef.current) {
      return ""
    }

    const optionValue = inputRef.current!.querySelector(
      `option[value="${value}"]`
    ) as HTMLOptionElement | null

    return optionValue ? optionValue.textContent || "" : (value as string)
  }

  return (
    <TagName className={classes}>
      <span
        className={classNames(
          style.label,
          style[`label--${labelStyle}`],
          overrideStyles && overrideStyles.inputLabel
        )}
      >
        {label}
      </span>

      {showError && errorPosition === "top" && <InputError {...props} />}

      <span
        className={classNames(
          style.input,
          overrideStyles && overrideStyles.input
        )}
      >
        <InputTagName ref={inputRef as any} {...inputProps} value={value} />

        {InputTagName === "select" && labelPosition === "Center" && (
          <span className={style["value--select"]}>{getSelectLabel()}</span>
        )}

        <span
          className={classNames(
            style.indicator,
            overrideStyles && overrideStyles.inputIndicator
          )}
        />
      </span>

      {showError && errorPosition === "bottom" && <InputError {...props} />}
    </TagName>
  )
}

export default Input
