import Globals from "./globals"
import Missions from "./missions"
import { AppState } from "./reducers"
import SignUp from "./sign-up"
import User from "./user"

const REDUX_STORE_KEY = "reduxStore"
const isServer = typeof localStorage === "undefined"

export const persistStore = (state: AppState) => {
  if (isServer) {
    return
  }

  const savedState = {
    globals: Globals.onPersistState(state.globals),
    signUp: SignUp.onPersistState(state.signUp),
    user: User.onPersistState(state.user),
    missions: Missions.onPersistState(state.missions)
  } as AppState

  localStorage.setItem(REDUX_STORE_KEY, JSON.stringify(savedState))
}

export const removePersistedStore = () => {
  if (isServer) {
    return
  }

  localStorage.removeItem(REDUX_STORE_KEY)
}

export const loadPersistedStore = (): AppState | undefined => {
  if (isServer) {
    return undefined
  }

  const savedStore = localStorage.getItem(REDUX_STORE_KEY)

  if (!savedStore) {
    return undefined
  }

  const deserializedStore = JSON.parse(savedStore)

  const revivedState = {
    globals: Globals.onReviveState(deserializedStore.globals),
    signUp: SignUp.onReviveState(deserializedStore.signUp),
    user: User.onReviveState(deserializedStore.user),
    missions: Missions.onReviveState(deserializedStore.missions)
  } as AppState

  return revivedState
}
