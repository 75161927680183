import classNames from "classnames"
import React, { useEffect, useState } from "react"
import Typography from "../foundation/typography"
import Icons from "../Icon"
import style from "./style.scss"

interface OverlayProps {
  type?: "loader" | "page" | "desktop" | "orientation"
  message?: string
  error?: string
  visible?: boolean
  icon?: keyof typeof Icons
  className?: string
}

const defaultProps = {
  "loader": {
    icon: "Planet",
    message: "Loading..."
  },
  "page": {
    icon: "Planet",
    message: "Loading..."
  },
  "desktop": {
    icon: "PlanetCrash",
    message:
      "Website not supported on this device. Please use a mobile device for optimal experience"
  },
  "orientation": {
    icon: "Phone",
    message: "Please rotate your device to portrait mode"
  }
} as {
  [index: string]: Partial<OverlayProps>
}

const Overlay: React.SFC<Readonly<OverlayProps>> = (props) => {
  const defaults = defaultProps[props.type || "loader"]

  const Icon = Icons[props.icon || defaults.icon!]

  return (
    <div
      className={classNames(style.overlay, props.className, {
        [style["overlay--visible"]]: props.visible,
        [style["overlay--loader"]]: props.type === "loader",
        [style["overlay--page"]]: props.type === "page",
        [style["overlay--desktop"]]: props.type === "desktop",
        [style["overlay--orientation"]]: props.type === "orientation"
      })}
    >
      <div className={style.header}>
        <Icon className={style.icon} />
        {Boolean(props.message || !props.error) && (
          <p className={style.title}>{props.message || defaults.message}</p>
        )}
      </div>

      {props.error && (
        <div className={style.error}>
          <Typography.P2 dangerouslySetInnerHTML={{ __html: props.error }} />
        </div>
      )}
    </div>
  )
}

export default Overlay
