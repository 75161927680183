import actions from "@/store/globals/actions"
import { LoadingMessage } from "@/store/globals/models"
import { AppState } from "@/store/reducers"
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import Overlay from "../Overlay"
import style from "./style.scss"

interface LayoutProps {
  className?: string
  errors: Error[]
  loadingMessages: LoadingMessage[]
  removeError: (error: Error) => void
}

const Layout: React.SFC<Readonly<LayoutProps>> = (props) => {
  const { children, className } = props

  const [loadingMessage, setLoadingMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    setTimeout(() => {
      props.errors.map(props.removeError)
    }, 5000)
  }, [props.errors])

  useEffect(() => {
    if (props.loadingMessages[0]) {
      setLoadingMessage(props.loadingMessages[0].message)

      if (!props.errors[0]) {
        setErrorMessage("")
      }
    }
  }, [props.loadingMessages])

  useEffect(() => {
    if (props.errors[0]) {
      setErrorMessage(props.errors[0].message)

      if (!props.loadingMessages[0]) {
        setLoadingMessage("")
      }
    }
  }, [props.errors])

  const showLoader = props.loadingMessages.length > 0 || props.errors.length > 0

  return (
    <main className={classNames(style.layout, className)}>
      {children}

      <Overlay
        type="loader"
        visible={showLoader}
        message={loadingMessage}
        error={errorMessage}
      />

      <Overlay type="page" />
      <Overlay type="desktop" />
      <Overlay type="orientation" />
    </main>
  )
}

export default connect(
  (state: AppState) => ({
    errors: state.globals.errors,
    loadingMessages: state.globals.loadingMessages
  }),
  (dispatch) => ({
    removeError: (error: Error) => dispatch(actions.removeError(error))
  })
)(Layout)
