import request from "@/lib/useApi"
import globalActions from "@/store/globals/actions"
import { LoadingMessage } from "@/store/globals/models"
import { removePersistedStore } from "@/store/persistence"
import { AppState } from "@/store/reducers"
import { User } from "@/store/user/models"
import { convertDateToBackendFormat } from "@/store/util/date-time"
import IDBDatabase from "@/store/util/IDBDatabaseService"
import _ from "lodash"
import querystring from "querystring"
import { SignUpFormModel } from "../sign-up/models"
import actions from "./actions"

export const endpoints = {
  login: "test-subject/login",
  logout: "test-subject/logout",
  privateLogin: "test-subject/private-login",
  resetPassword: "test-subject/reset",
  validateEmail: "test-subject/validate-email",
  validateSmsCode: "test-subject/reset/code",
  updatePassword: "test-subject/reset/password",
  getUserProfile: "test-subject/retrieve/profile",
  updateProfile: "test-subject/edit/profile",
  registerUser: "test-subject/new"
}

interface LoginProps {
  email: string
  password: string
}

export const login = (payload: Readonly<LoginProps>) => async (
  dispatch: any
) => {
  const message = new LoadingMessage("Logging in...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request.post(endpoints.login, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify(payload)
    })

    dispatch(actions.login(response))
    await dispatch(getUserProfile())
  } catch (e) {
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

export const logout = () => async (dispatch: any) => {
  dispatch({ type: "CLEAR" })
  removePersistedStore()
  await IDBDatabase.clear()
}

interface PrivateLoginProps {
  loginToken: string
}

export const privateLogin = (payload: Readonly<PrivateLoginProps>) => async (
  dispatch: any
) => {
  const message = new LoadingMessage("Logging in...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request.post(endpoints.privateLogin, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify({
        login_token: payload.loginToken
      })
    })

    dispatch(actions.privateLogin(response))
    await dispatch(getUserProfile())
  } catch (e) {
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface ResetPasswordProps {
  email: string
}

export const resetPassword = (payload: Readonly<ResetPasswordProps>) => async (
  dispatch: any
) => {
  const message = new LoadingMessage("Reseting password...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    await request.post(endpoints.resetPassword, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify(payload)
    })
  } catch (e) {
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface ValidateEmailProps {
  email: string
}

export const validateEmail = async (
  payload: Readonly<ValidateEmailProps>
): Promise<boolean> => {
  try {
    await request.post(endpoints.validateEmail, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify(payload)
    })

    return true
  } catch (e) {
    return false
  }
}

interface ValidateSmsCodeProps {
  email: string
  smsToken: string
}

export const validateSmsCode = (
  payload: Readonly<ValidateSmsCodeProps>
) => async (dispatch: any) => {
  const message = new LoadingMessage("Validating token...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request.post(endpoints.validateSmsCode, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify({
        email: payload.email,
        sms_token: payload.smsToken
      })
    })

    dispatch(actions.validateSmsCode(response))
  } catch (e) {
    dispatch(globalActions.pushError(e))
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface UpdatePasswordProps {
  password: string
}

export const updatePassword = (
  payload: Readonly<UpdatePasswordProps>
) => async (dispatch: any, getState: () => AppState) => {
  const message = new LoadingMessage("Updating password...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.updatePassword, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache"
        },
        body: querystring.stringify(payload)
      })

    dispatch(actions.updatePassword(response))

    await dispatch(getUserProfile())
  } catch (e) {
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface GetUserProfileProps {}

export const getUserProfile = (
  payload: Readonly<GetUserProfileProps> = {}
) => async (dispatch: any, getState: () => AppState) => {
  const message = new LoadingMessage("Getting profile...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.getUserProfile, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache"
        },
        body: querystring.stringify(payload)
      })
    dispatch(actions.getUserProfile(response))
  } catch (e) {
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

export const updateProfile = (payload: Readonly<Partial<User>>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  const message = new LoadingMessage("Updating profile...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const formData = new FormData()

    if (payload.email) {
      formData.append("email", payload.email)
    }

    if (payload.name) {
      formData.append("name", payload.name)
    }

    if (payload.postcode) {
      formData.append("postcode", payload.postcode)
    }

    formData.append(
      "dob",
      payload.dateOfBirth
        ? convertDateToBackendFormat(new Date(payload.dateOfBirth))
        : ""
    )
    formData.append("gender", payload.gender || "")
    formData.append("phone", payload.phone || "")

    if (payload.occupation) {
      formData.append("occupation", payload.occupation)
    }

    if (payload.livingSituation) {
      formData.append("living_situation", payload.livingSituation)
    }

    formData.append("profile_pic", payload.profilePic || "")

    const response = await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.updateProfile, {
        body: formData
      })

    dispatch(actions.updateProfile(response))
    await dispatch(getUserProfile())
  } catch (e) {
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

export const registerUser = (payload: Readonly<SignUpFormModel>) => async (
  dispatch: any
) => {
  const message = new LoadingMessage("Signing up...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    let profilePhoto: File | null = null

    if (payload.userProfile.profilePhotoId) {
      profilePhoto = await IDBDatabase.readFile({
        store: "auth",
        id: payload.userProfile.profilePhotoId
      })
    }

    const formData = new FormData()
    formData.append("email", payload.accountDetails.email)
    formData.append("name", payload.accountDetails.fullName)
    formData.append("postcode", payload.userProfile.postcode)
    formData.append(
      "dob",
      payload.userProfile.dateOfBirth
        ? convertDateToBackendFormat(payload.userProfile.dateOfBirth)
        : ""
    )
    formData.append("gender", payload.userProfile.gender || "")
    formData.append("phone", payload.phoneNumber.phoneNumber || "")
    formData.append("password", payload.accountDetails.password)
    formData.append("occupation", payload.additionalInfo.occupation)
    formData.append("living_situation", payload.additionalInfo.livingSituation)
    formData.append("profile_pic", profilePhoto || "")

    const response = await request.post(endpoints.registerUser, {
      body: formData
    })

    dispatch(actions.registerUser(response))
    dispatch(getUserProfile())
  } catch (e) {
    dispatch(globalActions.pushError(e))
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}
