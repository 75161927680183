import { Gender } from "../user/models"

export enum SignUpStep {
  AccountDetails = 1,
  UserProfile = 2,
  PhoneNumber = 3,
  AdditionalInfo = 4
}

export interface AccountDetailsModel {
  email: string
  fullName: string
  password: string
  confirmPassword: string
}

export interface UserProfileModel {
  profilePhotoId: string | undefined
  gender: Gender | undefined
  dateOfBirth: Date | undefined
  postcode: string
}

export interface AdditionalInfoModel {
  occupation: string
  livingSituation: string
  agreedToTerms: boolean
}

export interface PhoneNumberModel {
  phoneNumber: string
}

export interface SignUpFormModel {
  accountDetails: AccountDetailsModel
  userProfile: UserProfileModel
  phoneNumber: PhoneNumberModel
  additionalInfo: AdditionalInfoModel
}

export interface SignUpState {
  step: SignUpStep
  form: SignUpFormModel
}
