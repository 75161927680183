import { createActions } from "redux-actions"

const prefix = "MISSIONS"

const actionMap = {}

const actionTypes = [
  "GET_ALL_MISSIONS",
  "GET_MY_MISSIONS",
  "SET_ALL_MISSION_LOADING_STATUS",
  "SET_MY_MISSION_LOADING_STATUS",
  "SET_MISSION_INTERACTION_STATUS",
  "GET_MISSION_AND_TASKS",
  "STORE_TASK_DATA",
  "CLEAR_TASK_DATA"
]

export default createActions(actionMap, ...actionTypes, { prefix })
