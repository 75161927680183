import _ from "lodash"

export const convertDateToBackendFormat = (date: Date): string => {
  return (
    date.getFullYear() +
    "-" +
    _.padStart(String(date.getMonth() + 1), 2, "0") +
    "-" +
    _.padStart(String(date.getDate()), 2, "0")
  )
}

export const convertDateToFrontendFormat = (date: string): Date => {
  const [year, month, day, hours, minutes, seconds] = date
    .split(/[^0-9]/)
    .map(Number)

  return new Date(year, month - 1, day, hours, minutes, seconds)
}
