import _ from "lodash"
import validator from "validator"

// Default messages displayed
const messages = {
  isRequired: "This field is mandatory",
  isEmail: "Email address is invalid",
  isPostcode: "Please enter a valid postcode",
  isName: "Please enter a valid name",
  isPhoneNumber: "Please enter a valid phone number",
  matchFields: "These fields do not match",
  isOneOf: "Please select a valid option",
  isLessThan: "Your input exceeds the limit",
  isGreaterThan: "Your input is less than the minimum",
  isImage: "Please select a valid image",
  isDate: "Please select a valid date",
  isPassword: "Password must be at least 8 characters long",
  isFutureDate: "Please select a valid date"
}

export const isRequired = (value: any, message?: string) => {
  return (typeof value === "object" && _.isEmpty(value)) || !value
    ? message || messages.isRequired
    : undefined
}

export const isEmail = (value?: string, message?: string) => {
  return value && !validator.isEmail(value)
    ? message || messages.isEmail
    : undefined
}

export const isPostcode = (value?: string | number, message?: string) => {
  return value && !validator.isPostalCode(value.toString(), "AU")
    ? message || messages.isPostcode
    : undefined
}

export const isPhoneNumber = (value?: string, message?: string) => {
  return value && !/(^04[\d+]{8}$)/.test(value)
    ? message || messages.isPhoneNumber
    : undefined
}

export const matchFields = (values: any, message?: string) => {
  return values && values[0] !== values[1]
    ? message || messages.matchFields
    : undefined
}

export const isOneOf = (
  value: string | undefined,
  options: string[],
  message?: string
) => {
  return value && options.indexOf(value) === -1
    ? message || messages.isOneOf
    : undefined
}

export const isLessThanOrEqualTo = (
  value: number,
  comparison: number,
  message?: string
) => {
  return !value || value <= comparison
    ? undefined
    : message || messages.isLessThan
}

export const isLessThan = (
  value: number,
  comparison: number,
  message?: string
) => {
  return !value || value < comparison
    ? undefined
    : message || messages.isLessThan
}

export const isGreaterThan = (
  value: number,
  comparison: number,
  message?: string
) => {
  return !value || value > comparison
    ? undefined
    : message || messages.isGreaterThan
}

export const isImage = (value: any, message?: string) => {
  return !value || (value instanceof File && value.type.indexOf("image/") === 0)
    ? undefined
    : message || messages.isImage
}

export const isDate = (value: any, message?: string) => {
  return !value || (value instanceof Date && !isNaN(value.getTime()))
    ? undefined
    : message || messages.isDate
}

export const isPassword = (value: any, message?: string) => {
  return !value || value.length >= 8
    ? undefined
    : message || messages.isPassword
}

export const isFutureDate = (value?: Date, message?: string) => {
  return !value || new Date(value).getFullYear() < new Date().getFullYear()
    ? undefined
    : message || messages.isFutureDate
}

export const composeValidators = (...errors: Array<string | undefined>) => {
  return errors.reduce(
    (error: string | undefined, value: string | undefined) => {
      return error || value
    },
    undefined
  )
}
