import actions from "./actions"
import * as asyncActions from "./asyncActions"
import reducer, {
  initialState,
  onPersistState,
  onReviveState
} from "./reducers"

const Missions = {
  actions,
  asyncActions,
  reducer,
  initialState,
  onPersistState,
  onReviveState
}

export default Missions
