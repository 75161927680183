import { AppState } from "@/store/reducers"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

interface AuthenticatedProps {
  authenticated: boolean
  loggedIn: boolean
  children: any
}

const Authenticated: React.SFC<Readonly<AuthenticatedProps>> = (props) => {
  const router = useRouter()
  const [checkedAuth, setCheckedAuth] = useState<boolean>(false)

  useEffect(() => {
    if (props.authenticated && !props.loggedIn) {
      router.push("/login")
      return
    }

    if (!props.authenticated && props.loggedIn) {
      router.push("/missions")
      return
    }

    setCheckedAuth(true)
  }, [])

  return checkedAuth ? props.children : null
}

const AuthenticatedWithRedux = connect((state: AppState) => ({
  loggedIn: state.user.loggedIn
}))(Authenticated)

const Authentication = (props: { Component: any; authenticated: boolean }) => {
  const Component = props.Component

  return class extends React.Component<any> {
    public static async getInitialProps(appContext: any) {
      let appProps = {}

      if (Component.getInitialProps) {
        appProps = await Component.getInitialProps(appContext)
      }

      return {
        ...appProps
      }
    }

    public render() {
      return (
        <AuthenticatedWithRedux authenticated={props.authenticated}>
          <Component {...this.props} />
        </AuthenticatedWithRedux>
      )
    }
  }
}

export const withAuthentication = (Component: any) => {
  return Authentication({
    Component,
    authenticated: true
  })
}

export const withNoAuthentication = (Component: any) => {
  return Authentication({
    Component,
    authenticated: false
  })
}
