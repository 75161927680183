import _ from "lodash"

export const compare = (operator: string, v1: any, v2: any) => {
  switch (operator) {
    case "eq": {
      return v1 === v2
    }

    case "ne": {
      return v1 !== v2
    }

    case "gt": {
      return v1 > v2
    }

    case "ge": {
      return v1 >= v2
    }

    case "lt": {
      return v1 < v2
    }

    case "le": {
      return v1 <= v2
    }

    case "in": {
      return _.isArray(v1) && v1.includes(v2)
    }

    case "notin": {
      return _.isArray(v1) && !v1.includes(v2)
    }

    default: {
      return false
    }
  }
}

export function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj
}

export function getKey(obj: any, key: any) {
  if (hasKey(obj, key)) {
    return obj[key]
  }

  return null
}
