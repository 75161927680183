import { ContactFormModel } from "@/components/ContactForm"
import * as secrets from "@/env-config"
import request from "@/lib/useApi"
import { LoadingMessage } from "@/store/globals/models"
import { AppState } from "@/store/reducers"
import querystring from "querystring"
import actions from "./actions"

const endpoints = {
  submitContactForm: `${secrets.API_HOST}/contact`
}

export const submitContactForm = (
  payload: Readonly<ContactFormModel>
) => async (dispatch: any, getState: () => AppState) => {
  const loading = new LoadingMessage("Sending...")

  try {
    dispatch(actions.pushLoadingMessage(loading))

    await request.post(endpoints.submitContactForm, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Cache-Control": "no-cache"
      },
      body: querystring.stringify({
        name: payload.name,
        email: payload.email,
        regarding: payload.subject,
        message: payload.message
      })
    })
  } catch (e) {
    dispatch(actions.pushError(e))
  } finally {
    dispatch(actions.removeLoadingMessage(loading))
  }
}
