const configs = {
  local: {
    API_HOST: "https://blackmirror-pwa.docker-host.chepdev.com",
    API_PATH: "api",
    BUGSNAG_API_KEY: "a72590d07884bb58d4e476663bb36166"
  },
  staging: {
    API_HOST: "https://blackmirror-pwa.docker-host.chepdev.com",
    API_PATH: "api",
    BUGSNAG_API_KEY: "a72590d07884bb58d4e476663bb36166"
  },
  prod: {
    API_HOST: "https://blackmirror.com.au",
    API_PATH: "api",
    BUGSNAG_API_KEY: "a72590d07884bb58d4e476663bb36166"
  }
}

if (!configs[process.env.ENV]) {
  throw new Error(`Unknown environment: ${process.env.ENV}`)
}

const config = configs[process.env.ENV]

config.ENV = process.env.ENV
config.BUILD_ID = process.env.BUILD_ID

module.exports = config
