import { combineReducers } from "redux"

import Globals from "./globals"
import { GlobalState } from "./globals/models"
import Missions from "./missions"
import { MissionState } from "./missions/models"
import SignUp from "./sign-up"
import { SignUpState } from "./sign-up/models"
import User from "./user"
import { UserState } from "./user/models"

export interface AppState {
  globals: GlobalState
  signUp: SignUpState
  user: UserState
  missions: MissionState
}

const appReducer = combineReducers<AppState>({
  globals: Globals.reducer,
  signUp: SignUp.reducer,
  missions: Missions.reducer,
  user: User.reducer
})

const rootReducer = (state: AppState | undefined, action: any) => {
  if (action.type === "CLEAR") {
    state = {
      globals: Globals.initialState,
      signUp: SignUp.initialState,
      user: User.initialState,
      missions: Missions.initialState
    }
  }

  return appReducer(state, action)
}

export default rootReducer
