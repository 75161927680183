import request from "@/lib/useApi"
import globalActions from "@/store/globals/actions"
import { LoadingMessage } from "@/store/globals/models"
import {
  Mission,
  MissionInteractionStatus,
  MissionLoadingStatus,
  MissionType,
  TaskData,
  TaskResponse
} from "@/store/missions/models"
import { AppState } from "@/store/reducers"
import IDBDatabase from "@/store/util/IDBDatabaseService"
import "isomorphic-unfetch"
import _ from "lodash"
import querystring from "querystring"
import actions from "./actions"

export const endpoints = {
  getAllMissions: "missions/retrieve",
  submitTaskData: "missions/submit/data",
  getAttachmentSignedUrl: "missions/submit/attachment-via-signed-url",
  getMissionAndTasks: "missions/retrieve/:uuid",
  completeMission: "missions/submit/completion",
  acceptMission: "missions/accept"
}

interface GetAllMissions {
  missionType: MissionType
}

export const getAllMissions = (
  payload: Readonly<GetAllMissions> = { missionType: MissionType.HYBRID }
) => async (dispatch: any, getState: () => AppState) => {
  const status = getState().missions.allMissionsLoadingStatus

  if (status === MissionLoadingStatus.LOADING) {
    return
  }

  const message = new LoadingMessage("Loading missions...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.LOADING))

    const response = await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.getAllMissions, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache"
        },
        body: querystring.stringify({
          mission_type: payload.missionType
        })
      })

    if (typeof response === "undefined") {
      dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.INITIAL))
      return
    }

    dispatch(actions.getAllMissions(response))
    dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.LOADED))
  } catch (e) {
    dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.INITIAL))
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

export const storeTaskData = (payload: Readonly<TaskData>) => async (
  dispatch: any,
  getState: () => AppState
): Promise<TaskResponse[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const responses = await Promise.all(
        payload.responses.map(async (response) => {
          if (!(response.attachment instanceof File)) {
            return response
          }

          const attachmentId = await IDBDatabase.writeFile({
            store: "upload",
            data: response.attachment
          })

          return {
            ...response,
            attachment: attachmentId
          }
        })
      )

      resolve(responses)

      dispatch(
        actions.storeTaskData({
          responses,
          missionUuid: payload.missionUuid
        })
      )
    } catch (e) {
      dispatch(globalActions.pushError(e))
      reject(e)
    }
  })
}

export const submitTaskData = (payload: Readonly<TaskResponse>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (payload.attachment) {
        const attachment = await IDBDatabase.readFile({
          store: "upload",
          id: payload.attachment as IDBValidKey
        })

        const attachmentSignedUrl = await dispatch(
          getAttachmentSignedUrl({
            missionId: payload.missionId,
            taskId: payload.taskId,
            attachment
          })
        )

        await dispatch(
          submitAttachment({
            attachment,
            signedUrl: attachmentSignedUrl.signed_upload_url
          })
        )
      }

      resolve(
        request
          .authorizedAs(getState().user, dispatch)
          .post(endpoints.submitTaskData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Cache-Control": "no-cache"
            },
            body: querystring.stringify({
              user_id: payload.userId,
              mission_id: payload.missionUuid,
              task_id: payload.taskId,
              text_response: payload.textResponse
            })
          })
      )
    } catch (e) {
      reject(e)
    }
  })
}

export const submitAllTaskData = (mission: Readonly<Mission>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  const message = new LoadingMessage("Uploading mission...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.SUBMITTING)
    )

    const missionResponses = mission.getResponses()

    await Promise.all(
      missionResponses.map((step: TaskResponse) => {
        return dispatch(submitTaskData(step)) as Promise<void>
      })
    )

    await dispatch(
      completeMission({
        userId: mission.userId,
        missionUuid: mission.missionUuid
      })
    )

    await Promise.all(
      missionResponses
        .filter((i) => Boolean(i.attachment))
        .map((i) => i.attachment as IDBValidKey)
        .map((attachmentId: IDBValidKey) =>
          IDBDatabase.remove({ id: attachmentId, store: "upload" })
        )
    )

    dispatch(actions.clearTaskData(mission))

    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.SUBMITTED)
    )
  } catch (e) {
    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.COMPLETED)
    )
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface GetAttachmentSignedUrl {
  missionId: number
  taskId: number
  attachment: File
}

export const getAttachmentSignedUrl = (
  payload: Readonly<GetAttachmentSignedUrl>
) => async (dispatch: any, getState: () => AppState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await request
        .authorizedAs(getState().user, dispatch)
        .post(endpoints.getAttachmentSignedUrl, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "no-cache"
          },
          body: querystring.stringify({
            mission_id: payload.missionId,
            task_id: payload.taskId,
            file_extension:
              _.last(payload.attachment.name.split(".")) || "unknown",
            content_type: payload.attachment.type
          })
        })

      resolve(response)
    } catch (e) {
      reject(e)
    }
  })
}

interface SubmitAttachment {
  attachment: File
  signedUrl: string
}

export const submitAttachment = (payload: Readonly<SubmitAttachment>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  try {
    await fetch(payload.signedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": payload.attachment.type
      },
      body: payload.attachment
    })
  } catch (e) {
    throw e
  }
}

interface GetMissionAndTasks {
  missionUuid: string
}

export const getMissionAndTasks = (
  payload: Readonly<GetMissionAndTasks>
) => async (dispatch: any, getState: () => AppState) => {
  const message = new LoadingMessage("Loading mission...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    const response = await request
      .authorizedAs(getState().user, dispatch)
      .post(
        endpoints.getMissionAndTasks.replace(":uuid", payload.missionUuid),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control": "no-cache"
          }
        }
      )

    dispatch(actions.getMissionAndTasks(response))
  } catch (e) {
    dispatch(globalActions.pushError(e))
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}

interface CompleteMission {
  userId: string
  missionUuid: string
}

export const completeMission = (payload: Readonly<CompleteMission>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  try {
    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.COMPLETING)
    )

    await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.completeMission, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache"
        },
        body: querystring.stringify({
          user_id: payload.userId,
          mission_id: payload.missionUuid
        })
      })

    dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.INITIAL))
    await dispatch(getAllMissions())

    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.COMPLETED)
    )
  } catch (e) {
    dispatch(globalActions.pushError(e))
    throw e
  }
}

interface AcceptMission {
  missionUuid: string
}

export const acceptMission = (payload: Readonly<AcceptMission>) => async (
  dispatch: any,
  getState: () => AppState
) => {
  const message = new LoadingMessage("Accepting mission...")
  dispatch(globalActions.pushLoadingMessage(message))

  try {
    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.ACCEPTING)
    )

    await request
      .authorizedAs(getState().user, dispatch)
      .post(endpoints.acceptMission, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Cache-Control": "no-cache"
        },
        body: querystring.stringify({
          mission_id: payload.missionUuid
        })
      })

    dispatch(actions.setAllMissionLoadingStatus(MissionLoadingStatus.INITIAL))
    await dispatch(getAllMissions())

    dispatch(
      actions.setMissionInteractionStatus(MissionInteractionStatus.ACCEPTED)
    )
  } catch (e) {
    dispatch(globalActions.pushError(e))
    throw e
  } finally {
    dispatch(globalActions.removeLoadingMessage(message))
  }
}
