export const fileToArrayBuffer = (
  file: File | Blob
): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener("loadend", (e) => {
      resolve(reader.result)
    })

    reader.addEventListener("error", reject)
    reader.readAsArrayBuffer(file)
  })
}

export const bufferToBlob = (
  buffer: ArrayBuffer,
  options: BlobPropertyBag
): Blob => {
  return new Blob([buffer], options)
}

export const bufferToFile = (
  buffer: ArrayBuffer | Blob,
  name: string,
  options: FilePropertyBag
): File => {
  return new File([buffer], name, options)
}

export const dataUriToFile = (
  dataUri: string,
  name: string,
  options: FilePropertyBag
): File => {
  let byteString
  if (dataUri.split(",")[0].indexOf("base64") >= 0) {
    byteString = atob(dataUri.split(",")[1])
  } else {
    byteString = unescape(dataUri.split(",")[1])
  }

  const mimeString = dataUri
    .split(",")[0]
    .split(":")[1]
    .split(";")[0]
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([ia], name, { ...options, type: mimeString })
}

export const getFileDuration = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const video = document.createElement("video")
      video.preload = "metadata"

      const revoke = () => {
        video.src = ""
        URL.revokeObjectURL(video.src)
      }

      video.onloadedmetadata = () => {
        const duration = video.duration

        // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
        if (duration === Infinity) {
          video.currentTime = Number.MAX_SAFE_INTEGER
          return
        }

        revoke()
        resolve(duration)
      }

      video.addEventListener("timeupdate", () => {
        const duration = video.duration

        revoke()
        resolve(duration)
      })

      video.src = URL.createObjectURL(file)
    } catch (e) {
      reject(e)
    }
  })
}
