import { FSA } from "flux-standard-action"
import { ReducersMapObject } from "redux"
import { handleActions } from "redux-actions"

import actions from "./actions"
import { GlobalState, LoadingMessage } from "./models"

export const initialState: GlobalState = {
  errors: [],
  loadingMessages: []
}

export const onPersistState = (state: GlobalState): GlobalState => {
  return initialState
}

export const onReviveState = (state: GlobalState): GlobalState => {
  return state
}

const reducerMap = {
  [actions.pushError.toString()]: (
    state: GlobalState,
    { payload }: FSA<string, Error>
  ): GlobalState => {
    return {
      ...state,
      errors: [...state.errors, payload!]
    }
  },
  [actions.removeError.toString()]: (
    state: GlobalState,
    { payload }: FSA<string, Error>
  ): GlobalState => {
    return {
      ...state,
      errors: state.errors.filter((i) => i !== payload)
    }
  },
  [actions.pushLoadingMessage.toString()]: (
    state: GlobalState,
    { payload }: FSA<string, LoadingMessage>
  ): GlobalState => {
    return {
      ...state,
      loadingMessages: [...state.loadingMessages, payload!]
    }
  },
  [actions.removeLoadingMessage.toString()]: (
    state: GlobalState,
    { payload }: FSA<string, LoadingMessage>
  ): GlobalState => {
    return {
      ...state,
      loadingMessages: state.loadingMessages.filter((i) => i !== payload)
    }
  }
} as ReducersMapObject

export default handleActions<GlobalState>(reducerMap, initialState)
