import { FSA } from "flux-standard-action"
import { ReducersMapObject } from "redux"
import { handleActions } from "redux-actions"
import actions from "./actions"
import { UserState } from "./models"

export const initialState: UserState = {
  loggedIn: false,
  accessToken: null,
  user: null
}

export const onPersistState = (state: UserState): UserState => {
  return {
    ...state
  }
}

export const onReviveState = (state: UserState): UserState => {
  return state
}

const reducerMap = {
  [actions.login.toString()]: (
    state,
    { payload, error }: FSA<string, any>
  ): UserState => {
    if (error) {
      return { ...state }
    }

    return {
      ...state,
      loggedIn: true,
      accessToken: payload.token,
      user: {
        id: payload.uuid,
        type: payload.user_type
      }
    }
  },
  [actions.logout.toString()]: (state, { payload }: FSA): UserState => {
    return {
      ...state,
      loggedIn: false,
      accessToken: null,
      user: null
    }
  },
  [actions.getUserProfile.toString()]: (
    state,
    { payload, error }: FSA<string, any>
  ): UserState => {
    if (error) {
      return {
        ...state
      }
    }

    return {
      ...state,
      user: {
        ...state.user,
        dateOfBirth:
          payload.data.date_of_birth && new Date(payload.data.date_of_birth),
        email: payload.data.email,
        gender: (payload.data.gender || "na").toLowerCase(),
        livingSituation: payload.data.living_situation,
        name: payload.data.name,
        occupation: payload.data.occupation,
        phone: payload.data.phone,
        postcode: payload.data.postcode,
        profilePic: payload.data.profile_pic
      }
    }
  },
  [actions.updateProfile.toString()]: (
    state,
    { payload, error }: FSA<string, any>
  ): UserState => {
    return {
      ...state
    }
  },
  [actions.privateLogin.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): UserState => {
    return {
      ...state,
      loggedIn: true,
      accessToken: payload.token,
      user: {
        id: payload.uuid,
        type: "private"
      }
    }
  },
  [actions.resetPassword.toString()]: (state, { payload }: FSA): UserState => {
    return {
      ...state
    }
  },
  [actions.validateEmail.toString()]: (state, { payload }: FSA): UserState => {
    return {
      ...state
    }
  },
  [actions.validateSmsCode.toString()]: (
    state,
    { payload }: FSA<string, any>
  ): UserState => {
    return {
      ...state,
      loggedIn: true,
      accessToken: payload.token,
      user: {
        id: payload.uuid,
        type: payload.user_type
      }
    }
  },
  [actions.updatePassword.toString()]: (state, { payload }: FSA): UserState => {
    return {
      ...state
    }
  },
  [actions.registerUser.toString()]: (
    state,
    { payload, error }: FSA<string, any>
  ): UserState => {
    if (error) {
      return { ...state }
    }

    return {
      ...state,
      loggedIn: true,
      accessToken: payload.token,
      user: {
        id: payload.uuid,
        type: "public"
      }
    }
  }
} as ReducersMapObject

export default handleActions<UserState>(reducerMap, initialState)
