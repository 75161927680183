import * as secrets from "@/env-config"
import * as actions from "@/store/user/asyncActions"
import { UserState } from "@/store/user/models"
import "isomorphic-unfetch"
import _ from "lodash"
import Router from "next/router"

interface RequestProps {
  path: string
  method?: "GET" | "POST" | "PUT" | "DELETE"
  params: RequestInit
}

export class UnauthenticatedError extends Error {}

const request = () => {
  const getURL = (path: string) => {
    path = path.startsWith("/") ? path.slice(1) : path

    const host = `${secrets.API_HOST}/${secrets.API_PATH}/`
    return host + path
  }

  const createRequest = async (config: RequestProps) => {
    const { path, method, params } = config
    const url = path.includes("http") ? path : getURL(path)

    params.headers = {
      ...params.headers
    }

    const response = await fetch(url, { method, ...params })

    const data = await response.text().then((text) => {
      return text ? JSON.parse(text) : {}
    })

    if (!data.success) {
      if (data.message.includes("Token authentication failed")) {
        throw new UnauthenticatedError()
      }

      throw new Error(data.message)
    }

    return data
  }

  const methods = {
    get: async (path: string, params: RequestInit = {}) =>
      await createRequest({ method: "GET", path, params }),
    post: async (path: string, params: RequestInit = {}) =>
      await createRequest({ method: "POST", path, params }),
    put: async (path: string, params: RequestInit = {}) =>
      await createRequest({ method: "PUT", path, params }),
    delete: async (path: string, params: RequestInit = {}) =>
      await createRequest({ method: "DELETE", path, params })
  }

  return {
    ...methods,
    authorizedAs: (user: UserState, dispatch: any): typeof methods => {
      return _.mapValues(
        methods,
        (method) => async (path: string, params: RequestInit = {}) => {
          params.headers = _.merge(params.headers || {}, {
            "x-access-token": user.accessToken
          })

          try {
            return await method(path, params)
          } catch (e) {
            if (e instanceof UnauthenticatedError) {
              dispatch(actions.logout())
              Router.push("/login")
              return
            }

            throw e
          }
        }
      )
    }
  }
}

export default request()
