import Dexie from "dexie"
import { bufferToFile, fileToArrayBuffer } from "./file"

interface Transaction {
  store: string
}

interface WriteTransaction extends Transaction {
  data: any
}

interface ReadTransaction extends Transaction {
  id: IDBValidKey
}

interface DatabaseStores {
  [name: string]: Dexie.Table<any, number>
}

interface DatabaseOptions {
  name: string
  version: number
  stores: {
    [name: string]: string
  }
}

interface DatabaseService {
  write: (payload: WriteTransaction) => Promise<IDBValidKey>
  writeFile: (payload: WriteTransaction) => Promise<IDBValidKey>
  read: (payload: ReadTransaction) => Promise<any>
  readFile: (payload: ReadTransaction) => Promise<File>
  remove: (payload: ReadTransaction) => Promise<void>
  clear: () => Promise<void>
}

class IDBDatabaseService extends Dexie implements DatabaseService {
  private stores: DatabaseStores = {}

  constructor(props: DatabaseOptions) {
    super(props.name)

    this.version(1).stores(props.stores)

    Object.keys(props.stores).forEach((name: string) => {
      this.stores[name] = this.table(name)
    })
  }

  public async write(payload: WriteTransaction): Promise<IDBValidKey> {
    return this.stores[payload.store].add(payload.data)
  }

  public async writeFile(payload: WriteTransaction): Promise<IDBValidKey> {
    const file = await fileToArrayBuffer(payload.data)

    return this.write({
      store: payload.store,
      data: {
        file,
        type: payload.data.type,
        name: payload.data.name
      }
    })
  }

  public async read(payload: ReadTransaction): Promise<any> {
    return this.stores[payload.store].get(payload.id as number)
  }

  public async readFile(payload: ReadTransaction): Promise<File> {
    return this.read(payload).then((response) => {
      return bufferToFile(response.file, response.name, {
        type: response.type
      })
    })
  }

  public async remove(payload: ReadTransaction): Promise<void> {
    await this.stores[payload.store]
      .where("id")
      .equals(payload.id as number)
      .delete()
  }

  public async clear(): Promise<void> {
    await Promise.all(
      Object.keys(this.stores).map((name: string) => {
        return this.stores[name].clear()
      })
    )
  }
}

const IDBDatabase = new IDBDatabaseService({
  name: "blackmirror",
  version: 1,
  stores: {
    upload: "++id",
    auth: "++id"
  }
})

export default IDBDatabase
