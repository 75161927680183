import { initializeStore } from "@/store"
import React from "react"

const isServer = typeof window === "undefined"
const __NEXT_REDUX_STORE__ = "__NEXT_REDUX_STORE__"

function getOrCreateStore() {
  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return initializeStore()
  }

  // Store in global variable if client
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = initializeStore()
  }
  return window[__NEXT_REDUX_STORE__]
}

export type Store = ReturnType<typeof getOrCreateStore>

interface Props {
  reduxStore: Store
}

const withReduxStore = (
  Component: React.ComponentClass<Props> | React.SFC<Props>
) => {
  return class Redux extends React.Component<any> {
    public static async getInitialProps(appContext: any) {
      const reduxStore = getOrCreateStore()

      // Provide the store to getInitialProps of pages
      appContext.ctx.reduxStore = reduxStore

      let appProps = {}

      if ((Component as any).getInitialProps) {
        appProps = await (Component as any).getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialReduxState: reduxStore.getState()
      }
    }

    private reduxStore: any

    constructor(props: any) {
      super(props)
      this.reduxStore = getOrCreateStore()
    }

    public render() {
      return <Component {...this.props} reduxStore={this.reduxStore} />
    }
  }
}

export default withReduxStore

export const mapDispatchToProps = (dispatch: any) => ({ dispatch })

export type Dispatchable<P> = P & ReturnType<typeof mapDispatchToProps>
