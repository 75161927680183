import classNames from "classnames"
import React, { forwardRef } from "react"
import styles from "./typography.scss"

interface TypeProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLElement>,
    HTMLElement
  > {
  as?: any
  additionalClassName?: any
  ref: React.Ref<any>
}

const Type = forwardRef<any, Readonly<TypeProps>>((props, ref) => {
  const { as, className, additionalClassName, ...componentProps } = props

  const Component = as || ("div" as any)

  const classes = classNames(className, additionalClassName)

  return <Component {...componentProps} ref={ref} className={classes} />
})

const Typography = {
  H1: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h1"
      additionalClassName={styles["type--h1"]}
      {...props}
    />
  )),
  H2: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h2"
      additionalClassName={styles["type--h2"]}
      {...props}
    />
  )),
  H3: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h3"
      additionalClassName={styles["type--h3"]}
      {...props}
    />
  )),
  H4: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h4"
      additionalClassName={styles["type--h4"]}
      {...props}
    />
  )),
  H5: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h5"
      additionalClassName={styles["type--h5"]}
      {...props}
    />
  )),
  H6: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="h6"
      additionalClassName={styles["type--h6"]}
      {...props}
    />
  )),
  P1: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="p"
      additionalClassName={styles["type--p1"]}
      {...props}
    />
  )),
  P2: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="p"
      additionalClassName={styles["type--p2"]}
      {...props}
    />
  )),
  P3: forwardRef<any, TypeProps>((props, ref) => (
    <Type
      ref={ref}
      as="p"
      additionalClassName={styles["type--p3"]}
      {...props}
    />
  )),
  LINK: forwardRef<any, TypeProps & { href?: string; target?: string }>(
    (props, ref) => (
      <Type
        as="a"
        ref={ref}
        additionalClassName={styles["type--link"]}
        {...props}
      />
    )
  )
}

export default Typography
