import EmotionIndicatorEmbedded from "!!svg-url-loader?stripdeclarations&prefix=static/!../../static/icons/emotion-indicator.svg"
import Camera from "@/static/icons/camera.svg"
import ChevronLeft from "@/static/icons/chevron-left.svg"
import Cog from "@/static/icons/cog.svg"
import Cross from "@/static/icons/cross.svg"
import EmotionIndicator from "@/static/icons/emotion-indicator.svg"
import Information from "@/static/icons/information-icon.svg"
import Logo from "@/static/icons/logo.svg"
import MoneyGroup from "@/static/icons/money-group.svg"
import MoneyNote from "@/static/icons/money-note.svg"
import MoneyWad from "@/static/icons/money-wad.svg"
import Pencil from "@/static/icons/pencil.svg"
import Person from "@/static/icons/person.svg"
import Phone from "@/static/icons/phone.svg"
import PlanetCrash from "@/static/icons/planet-crash.svg"
import PlanetSparkle from "@/static/icons/planet-sparkle.svg"
import PlanetTakeOffSparkle from "@/static/icons/planet-take-off-sparkle.svg"
import PlanetTakeOff from "@/static/icons/planet-take-off.svg"
import PlanetWithFlagSparkle from "@/static/icons/planet-with-flag-sparkle.svg"
import PlanetWithFlag from "@/static/icons/planet-with-flag.svg"
import Planet from "@/static/icons/planet.svg"
import RocketLaunchSparkle from "@/static/icons/rocket-launch-sparkle.svg"
import RocketLaunch from "@/static/icons/rocket-launch.svg"
import Rocket from "@/static/icons/rocket.svg"
import Select from "@/static/icons/select.svg"
import SignOut from "@/static/icons/signout.svg"
import Sparkle from "@/static/icons/sparkle.svg"
import VideoCamera from "@/static/icons/video-camera.svg"
import { HTMLElementProps } from "@/types/props"
import React from "react"

interface IconProps extends HTMLElementProps {
  icon?: React.ComponentType | string
}

const Icon: React.SFC<IconProps> = ({ icon, ...props }) => {
  const IconComponent = icon!
  return <IconComponent className="icon" {...props} />
}

const Icons = {
  Logo: (props: IconProps) => <Icon icon={Logo} {...props} />,
  Camera: (props: IconProps) => <Icon icon={Camera} {...props} />,
  ChevronLeft: (props: IconProps) => <Icon icon={ChevronLeft} {...props} />,
  Cog: (props: IconProps) => <Icon icon={Cog} {...props} />,
  Cross: (props: IconProps) => <Icon icon={Cross} {...props} />,
  EmotionIndicator: (props: IconProps) => (
    <img
      className="icon"
      src={EmotionIndicatorEmbedded}
      {...props}
    /> /* hack */
  ),
  Information: (props: IconProps) => <Icon icon={Information} {...props} />,
  MoneyNote: (props: IconProps) => <Icon icon={MoneyNote} {...props} />,
  MoneyWad: (props: IconProps) => <Icon icon={MoneyWad} {...props} />,
  MoneyGroup: (props: IconProps) => <Icon icon={MoneyGroup} {...props} />,
  Pencil: (props: IconProps) => <Icon icon={Pencil} {...props} />,
  Person: (props: IconProps) => <Icon icon={Person} {...props} />,
  PlanetCrash: (props: IconProps) => <Icon icon={PlanetCrash} {...props} />,
  PlanetTakeOff: (props: IconProps) => <Icon icon={PlanetTakeOff} {...props} />,
  PlanetTakeOffSparkle: (props: IconProps) => (
    <Icon icon={PlanetTakeOffSparkle} {...props} />
  ),
  PlanetWithFlag: (props: IconProps) => (
    <Icon icon={PlanetWithFlag} {...props} />
  ),
  PlanetWithFlagSparkle: (props: IconProps) => (
    <Icon icon={PlanetWithFlagSparkle} {...props} />
  ),
  Planet: (props: IconProps) => <Icon icon={Planet} {...props} />,
  PlanetSparkle: (props: IconProps) => <Icon icon={PlanetSparkle} {...props} />,
  Phone: (props: IconProps) => <Icon icon={Phone} {...props} />,
  RocketLaunch: (props: IconProps) => <Icon icon={RocketLaunch} {...props} />,
  RocketLaunchSparkle: (props: IconProps) => (
    <Icon icon={RocketLaunchSparkle} {...props} />
  ),
  Rocket: (props: IconProps) => <Icon icon={Rocket} {...props} />,
  Select: (props: IconProps) => <Icon icon={Select} {...props} />,
  Sparkle: (props: IconProps) => <Icon icon={Sparkle} {...props} />,
  VideoCamera: (props: IconProps) => <Icon icon={VideoCamera} {...props} />,
  SignOut: (props: IconProps) => <Icon icon={SignOut} {...props} />
}

export default Icons
