import { createActions } from "redux-actions"

const prefix = "GLOBALS"

const actionMap = {}

const actionTypes = [
  "PUSH_ERROR",
  "REMOVE_ERROR",
  "PUSH_LOADING_MESSAGE",
  "REMOVE_LOADING_MESSAGE"
]

export default createActions(actionMap, ...actionTypes, { prefix })
