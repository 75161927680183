import React, { useEffect, useRef } from "react"

const useIsMounted = (): React.RefObject<boolean> => {
  const ref = useRef<boolean>(true)

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  return ref
}

export default useIsMounted
