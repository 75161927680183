import Icons from "@/components/Icon"
import classNames from "classnames"
import React from "react"
import style from "./style.scss"

interface LogoProps {
  className?: string
  colour?: string
}

const getLogoColour = (colour: string | undefined) => {
  if (colour) {
    switch (colour) {
      case "colourPortage":
        return "colour-portage"
      case "colourGreen":
        return "colour-green"
    }
  }
  return "default"
}

const Logo: React.SFC<Readonly<LogoProps>> = (props) => {
  const { className, colour } = props

  return (
    <header className={classNames(style.header, className)}>
      <h1 className={style.title}>Black Mirror</h1>
      <Icons.Logo
        className={classNames(
          style.logo,
          style[`logo--${getLogoColour(colour)}`]
        )}
      />
    </header>
  )
}

export default Logo
