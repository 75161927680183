import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import middleware from "./middleware"
import { loadPersistedStore, persistStore } from "./persistence"
import reducers, { AppState } from "./reducers"

export function initializeStore() {
  const store = createStore<AppState, any, {}, {}>(
    reducers,
    loadPersistedStore(),
    composeWithDevTools(applyMiddleware(...middleware))
  )

  store.subscribe(() => persistStore(store.getState()))

  return store
}
