import classNames from "classnames"
import React, { forwardRef } from "react"
import Icons from "../Icon"
import style from "./style.scss"

interface ButtonProps {
  autoFocus?: boolean
  disabled?: boolean
  form?: string
  formAction?: string
  formEncType?: string
  formMethod?: string
  formNoValidate?: boolean
  formTarget?: string
  name?: string
  type?: any
  value?: string | string[] | number
  href?: any

  /**
   * Text label for the button.
   *
   * Use `children` to supply JSX content. This prop actually supports JSX elements,
   * but TypeScript will throw an error because `HTMLAttributes` only allows strings.
   */
  label?: string
  /**
   * Name of the HTML tag that wraps button.
   *
   * By default a `<button>` is used
   *
   * @default "button"
   */
  tagName?: any
}

const Button = forwardRef<
  any,
  Readonly<ButtonProps & React.HTMLAttributes<any>>
>((props, ref) => {
  const {
    children,
    className,
    label,
    tagName: TagName = "button",
    ...buttonProps
  } = props

  const classes = classNames(style.button, className)

  return (
    <>
      <TagName {...buttonProps} className={classes} ref={ref}>
        {label && <span className={style.label}>{label}</span>}
        {children}
      </TagName>
    </>
  )
})

export const InlineButton = forwardRef<
  HTMLButtonElement,
  Readonly<ButtonProps & React.HTMLAttributes<any>>
>((props, ref) => (
  <Button
    {...props}
    ref={ref}
    className={classNames(style["button--inline"], props.className)}
  />
))

interface IconButtonProps extends ButtonProps {
  icon?: keyof typeof Icons
}

export const IconButton = forwardRef<
  HTMLButtonElement,
  Readonly<IconButtonProps & React.HTMLAttributes<any>>
>((props, ref) => {
  const Icon = props.icon && Icons[props.icon]

  return (
    <Button
      type="button"
      ref={ref}
      {...props}
      className={classNames(style["button--icon"], props.className)}
    >
      {Icon && <Icon />}
    </Button>
  )
})

export default Button
