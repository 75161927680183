import _ from "lodash"
import { useEffect, useState } from "react"
import useIsMounted from "../useIsMounted"

export enum FormStatus {
  EDITING = "EDITING",
  SUBMITTING = "SUBMITTING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED"
}

class InvalidFormError extends Error {}

type InputType = Date | File | string | boolean | undefined | number

type FormErrors<TValues> = {
  [Key in keyof TValues]?: TValues[Key] extends InputType
    ? string
    : FormErrors<TValues[Key]>
}

type FormTouched<TValues> = {
  [Key in keyof TValues]?: TValues[Key] extends InputType
    ? boolean
    : FormTouched<TValues[Key]>
}

interface FormOptions<TValues> {
  /**
   * Initial form values
   */
  initialValues: Partial<TValues>
  /**
   * Input handler
   */
  onInput?: (values: Partial<TValues>) => void
  /**
   * Submission handler
   */
  onSubmit: (values: TValues) => void
  /**
   * Validation check that occurs prior to the submission handler.
   */
  validate: (
    values: Partial<TValues>
  ) => FormErrors<TValues> | Promise<FormErrors<TValues>>
  /**
   * Indicates if the form should re-validate the input on blur.
   */
  validateOnBlur?: boolean
  /**
   * Indicates if the form should be re-validated on input change. Only
   * fired when all fields have been touched that exist within the
   * `initialValues` object.
   */
  validateOnChange?: boolean
}

interface FormState<TValues> {
  mode: FormStatus
  /**
   * Map of field names and the error of that field
   */
  errors: FormErrors<TValues>
  /**
   * Map of field names and their values
   */
  values: Partial<TValues>
  /**
   * Map of field names and if they have been touched
   */
  touched: FormTouched<TValues>
  /**
   * Blur handler, marks a field as `touched`
   */
  handleBlur: (event: React.FocusEvent<any>) => void
  /**
   * Focus handler, resets a fields validation
   */
  handleFocus: (event: React.FocusEvent<any>) => void
  /**
   * Change handler, changes the field in the `values` state
   */
  handleChange: (event: React.ChangeEvent<any> | any, name?: string) => void
  /**
   * Submission handler, handle calling validation prior to the submission
   * handler, and manging the `touched`, `errors` and `isSubmitting` state.
   */
  handleSubmit: (event: React.ChangeEvent<HTMLFormElement>) => Promise<void>
}

function useForm<TValues>(options: FormOptions<TValues>): FormState<TValues> {
  const {
    initialValues,
    onInput = () => {},
    onSubmit,
    validate,
    validateOnChange,
    validateOnBlur
  } = options

  const [mode, setMode] = useState<FormStatus>(FormStatus.EDITING)
  const [values, setValues] = useState<Partial<TValues>>(initialValues || {})
  const [touched, setTouched] = useState<FormTouched<TValues>>({})
  const [errors, setErrors] = useState<FormErrors<TValues>>({})
  const isMounted = useIsMounted()

  useEffect(() => {
    onInput(values)
  }, [values])

  const getValue = (event: React.ChangeEvent<any>) => {
    if (!event.target) {
      return event
    }

    const { checked, type, value } = event.target as HTMLInputElement

    if (/number|range/.test(type)) {
      const parsed = parseFloat(value)
      return isNaN(parsed) ? "" : parsed
    } else if (/checkbox/.test(type)) {
      return checked
    }
    return value
  }

  const handleValidate = async (nextValues?: Partial<TValues>) => {
    return Promise.resolve(validate(nextValues || values)).then(
      (newErrors: any) => {
        const filteredErrors = _.pickBy(newErrors) as any
        setErrors(filteredErrors)
        return filteredErrors
      }
    )
  }

  const handleFocus = (event: React.ChangeEvent<any>): void => {
    const name: keyof TValues = event.target.name
    const { [name]: value, ...newErrors }: any = errors

    setErrors(newErrors)
  }

  const handleBlur = (event: React.ChangeEvent<any>): void => {
    const name: keyof TValues = event.target.name
    setTouched({ ...touched, [name]: true } as any)

    if (validateOnBlur) {
      handleValidate()
    }
  }

  const handleChange = (event: React.ChangeEvent<any>, name?: string) => {
    if (!event) {
      return
    }

    const nextValues = {
      ...values,
      [name || event.target.name]: getValue(event)
    } as any

    setValues(nextValues)
    setTouched({ ...touched, [name || event.target.name]: true } as any)

    if (validateOnChange) {
      handleValidate(nextValues)
    }
    return nextValues
  }

  const handleSubmit = async (event: React.ChangeEvent<any>): Promise<void> => {
    event.preventDefault()
    setMode(FormStatus.SUBMITTING)

    const fields = [...Object.keys(values), ...Object.keys(initialValues)]

    setTouched({
      ...touched,
      ...fields.reduce(
        (a, field) => {
          a[field] = true
          return a
        },
        {} as any
      )
    })

    return Promise.resolve(handleValidate(values))
      .then((newErrors: any) => {
        if (!Object.keys(newErrors).length) {
          return Promise.resolve(onSubmit(values as TValues))
        }

        return Promise.reject(new InvalidFormError())
      })
      .then(() => {
        if (isMounted.current) {
          setMode(FormStatus.SUCCESS)
        }
      })
      .catch((error) => {
        if (error instanceof InvalidFormError) {
          setMode(FormStatus.EDITING)
          return
        }

        setMode(FormStatus.FAILED)
        return Promise.reject(error)
      })
  }

  return {
    mode,
    errors,
    values,
    touched,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit
  }
}

export default useForm
