import actions from "./actions"
import reducer, {
  initialState,
  onPersistState,
  onReviveState
} from "./reducers"

const SignUp = {
  actions,
  reducer,
  initialState,
  onPersistState,
  onReviveState
}

export default SignUp
