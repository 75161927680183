import _ from "lodash"
import { convertDateToFrontendFormat } from "../util/date-time"
import { assignWithNewProps } from "../util/immutability"

export enum MissionDuration {
  ALWAYS_AVAILABLE = "always available",
  NOT_STARTED = "not started",
  IN_PROGRESS = "in progress",
  FINISHED = "finished",
  NOT_AVAILABLE = "not available"
}

export enum MissionStatus {
  NOT_ACCEPTED = "not accepted",
  PENDING = "pending",
  AWAITING_UPLOAD = "awaiting upload",
  AWAITING_MODERATION = "awaiting moderation",
  QUOTA_FULL = "quota full",
  ACCEPTED = "accepted",
  REJECTED = "rejected"
}

export enum MissionType {
  PUBLIC = "public",
  PRIVATE = "private",
  HYBRID = "hybrid"
}

export enum MissionLoadingStatus {
  INITIAL = "initial",
  LOADING = "loading",
  LOADED = "loaded"
}

export enum MissionInteractionStatus {
  INITIAL = "initial",
  ACCEPTING = "accepting",
  ACCEPTED = "accepted",
  SUBMITTING = "submitting",
  SUBMITTED = "submitted",
  COMPLETING = "completing",
  COMPLETED = "completed"
}

export enum TaskType {
  TEXT = "text",
  INPUT = "input",
  VIDEO = "video",
  PHOTO = "photo",
  EMOTION = "emotion",
  DIAL = "dial"
}

export class Mission {
  public static fromAPI(payload: any, state: MissionState): Mission {
    const mission = new Mission()

    const existingMission = ((state.allMissions &&
      state.allMissions.find((i: Mission) => i.missionUuid === payload.uuid)) ||
      {}) as Partial<Mission>

    assignWithNewProps(mission, existingMission, {
      id: payload.id,
      title: payload.title,
      description: payload.description,
      image: payload.image,
      startDate: convertDateToFrontendFormat(payload.start_date),
      endDate: convertDateToFrontendFormat(payload.end_date),
      createdBy: payload.created_by,
      active: !!payload.active,
      missionSize: payload.mission_size,
      createdAt: convertDateToFrontendFormat(payload.createdAt),
      updatedAt: convertDateToFrontendFormat(payload.updatedAt),
      missionUuid: payload.uuid,
      incentive: payload.incentive,
      client: payload.client,
      taskCount: payload.task_count || existingMission.taskCount,
      acceptanceCount:
        payload.acceptance_count || existingMission.acceptanceCount,
      moderationResponse:
        payload.moderation_response || existingMission.moderationResponse,
      statusMessage: payload.status_message || existingMission.statusMessage,
      userId: payload.userId,
      webpage: payload.webpage,
      instructions: !!payload.instructions,
      tasks: payload.tasks
        ? payload.tasks.map((task: any) => Task.fromAPI(task, existingMission))
        : (existingMission && existingMission.tasks) || []
    })

    return mission
  }

  public static serialize(instance: Mission): any {
    const object = {}

    Object.assign(object, {
      ...instance,
      tasks: instance.tasks.map(Task.serialize)
    })

    return object
  }

  public static deserialize(object: any): Mission {
    const instance = new Mission()

    Object.assign(instance, {
      ...object,
      startDate: new Date(object.startDate),
      endDate: new Date(object.endDate),
      createdAt: new Date(object.createdAt),
      updatedAt: new Date(object.updatedAt),
      tasks: object.tasks.map(Task.deserialize)
    })

    return instance
  }

  public id: number = 0
  public title: string = ""
  public description: string = ""
  public image: string = ""
  public startDate: Date | string | null = ""
  public endDate: Date | string | null = ""
  public createdBy: string = ""
  public active: boolean = false
  public missionSize: number = 0
  public createdAt: Date = new Date()
  public updatedAt: Date = new Date()
  public missionUuid: string = ""
  public incentive: number | null = null
  public client: string = ""
  public taskCount: number = 0
  public acceptanceCount: number = 0
  public moderationResponse: MissionStatus = MissionStatus.NOT_ACCEPTED
  public statusMessage: string | null = null
  public userId: string = ""
  public webpage: string = ""
  public instructions: boolean = false
  public tasks: Task[] = []
  public isTutorial: boolean = false

  public getAmountOfCompleteTasks = (): number => {
    return this.tasks.filter((i) => i.isComplete()).length
  }

  public getSize = (): string | undefined => {
    return this.tasks
      .reduce((a, v) => {
        return v.response && v.response.size ? a + v.response.size : a
      }, 0)
      .toFixed(2)
  }

  public getStatus = (): MissionStatus => {
    if (this.isTutorial) {
      return MissionStatus.NOT_ACCEPTED
    }

    const tasksComplete = this.tasks.filter((task) => task.isComplete())

    const isAwaitingUpload =
      tasksComplete.length === this.taskCount &&
      this.moderationResponse === MissionStatus.PENDING

    const isQuotaFull =
      this.moderationResponse === MissionStatus.NOT_ACCEPTED &&
      this.acceptanceCount >= this.missionSize

    const status = isAwaitingUpload
      ? MissionStatus.AWAITING_UPLOAD
      : isQuotaFull
      ? MissionStatus.QUOTA_FULL
      : this.moderationResponse

    return status
  }

  public isComplete = (): boolean => {
    const status = this.getStatus()

    return (
      status === MissionStatus.ACCEPTED ||
      status === MissionStatus.AWAITING_MODERATION ||
      status === MissionStatus.REJECTED ||
      status === MissionStatus.QUOTA_FULL
    )
  }

  public isUnavailable = (): boolean => {
    const duration = this.getDuration()

    return (
      duration === MissionDuration.NOT_STARTED ||
      duration === MissionDuration.FINISHED ||
      duration === MissionDuration.NOT_AVAILABLE
    )
  }

  public getDuration = (): MissionDuration => {
    const now = new Date()

    if (this.isTutorial) {
      return MissionDuration.ALWAYS_AVAILABLE
    }

    if (
      !this.startDate ||
      (!_.isDate(this.startDate) || (!this.endDate || !_.isDate(this.endDate)))
    ) {
      return MissionDuration.NOT_AVAILABLE
    }

    if (now.getTime() < this.startDate.getTime()) {
      return MissionDuration.NOT_STARTED
    }

    if (now.getTime() > this.endDate.getTime()) {
      return MissionDuration.FINISHED
    }

    return MissionDuration.IN_PROGRESS
  }

  public getDaysUntilMissionsEnds = (): number => {
    const now = new Date()

    if (_.isDate(this.endDate)) {
      return Math.ceil(
        (this.endDate.getTime() - now.getTime()) / 1000 / 60 / 60 / 24
      )
    }

    throw new Error("There is no end date for this mission...")
  }

  public getResponses = (): TaskResponse[] => {
    return this.tasks.filter((i) => i.response).map((i) => i.response!)
  }

  public getRemainingTasks = (): number => {
    return this.tasks.length > 0
      ? this.tasks.length - this.tasks.filter((i) => i.isComplete()).length
      : this.taskCount
  }

  public update = (newProps: Partial<Mission>): Mission => {
    const mission = new Mission()

    assignWithNewProps(mission, this, newProps)

    return mission
  }

  public clearTaskResponses = () => {
    return this.update({
      tasks: this.tasks.map((task) => {
        return task.update({ response: undefined })
      })
    })
  }

  public updateTaskResponses = (taskResponses: TaskResponse[]): Mission => {
    return this.update({
      tasks: this.tasks.map((task) => {
        const response = taskResponses.find((i: any) => i.taskId === task.id)

        return response ? task.update({ response }) : task
      })
    })
  }
}

export class Task {
  public static fromAPI(payload: any, mission?: Partial<Mission>): Task {
    const task = new Task()

    const existingTask =
      (mission &&
        mission.tasks &&
        mission.tasks.find((i: any) => i.id === payload.id)) ||
      ({} as Partial<Task>)

    assignWithNewProps(task, existingTask, {
      id: payload.id,
      taskId: payload.task_id,
      name: payload.name,
      question: payload.question,
      type: payload.type,
      duration: payload.duration,
      missionId: payload.missionId,
      response: existingTask.response
    })

    return task
  }

  public static serialize(instance: Task): any {
    const object = {}

    Object.assign(object, instance)

    return object
  }

  public static deserialize(object: any): Task {
    const instance = new Task()

    Object.assign(instance, object)

    return instance
  }

  public id: number = 0
  public taskId: number = 0
  public missionId: number = 0
  public name: string = ""
  public type: TaskType = TaskType.TEXT
  public question: string = ""
  public duration: number = 0
  public parent?: number
  public response?: TaskResponse

  public isComplete = (): boolean => {
    return !!this.response && this.response.complete
  }

  public update = (newProps: Partial<Task>): Task => {
    const task = new Task()

    assignWithNewProps(task, this, newProps)

    return task
  }
}

export interface TaskResponse {
  textResponse: string
  missionUuid: string
  missionId: number
  userId: string
  taskId: number
  type: string
  attachment?: File | IDBValidKey
  size?: number
  complete: boolean
}

export interface TaskData {
  responses: TaskResponse[]
  missionUuid: string
}

export interface MissionState {
  allMissionsLoadingStatus: MissionLoadingStatus
  missionInteractionStatus: MissionInteractionStatus
  allMissions: Mission[] | null
}
