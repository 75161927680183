import { FSA } from "flux-standard-action"
import _ from "lodash"
import { ReducersMapObject } from "redux"
import { handleActions } from "redux-actions"
import actions from "./actions"
import { SignUpFormModel, SignUpState, SignUpStep } from "./models"

export const initialState: SignUpState = {
  step: SignUpStep.AccountDetails,
  form: {
    accountDetails: {
      email: "",
      fullName: "",
      password: "",
      confirmPassword: ""
    },
    userProfile: {
      profilePhotoId: undefined,
      gender: undefined,
      dateOfBirth: undefined,
      postcode: ""
    },
    phoneNumber: {
      phoneNumber: ""
    },
    additionalInfo: {
      occupation: "",
      livingSituation: "",
      agreedToTerms: false
    }
  }
}

export const onPersistState = (state: SignUpState): SignUpState => {
  return state
}

export const onReviveState = (state: SignUpState): SignUpState => {
  return state
}

const reducerMap = {
  [actions.previousStep.toString()]: (
    state: SignUpState,
    { payload }: FSA<string, any>
  ): SignUpState => {
    return {
      ...state,
      step: state.step - 1
    }
  },
  [actions.nextStep.toString()]: (
    state: SignUpState,
    { payload }: FSA<string, any>
  ): SignUpState => {
    return {
      ...state,
      step: state.step + 1
    }
  },
  [actions.setFormState.toString()]: (
    state: SignUpState,
    { payload }: FSA<string, Partial<SignUpFormModel>>
  ): SignUpState => {
    return {
      ...state,
      form: {
        ...state.form,
        ...payload
      }
    }
  },
  [actions.clear.toString()]: (
    state: SignUpState,
    { payload }: FSA<string, any>
  ): SignUpState => {
    return initialState
  }
} as ReducersMapObject

export default handleActions<SignUpState>(reducerMap, initialState)
